import React from 'react'
import { withTranslation } from 'react-i18next';

import {Footer, SignupDivider, 
Templatethumbs} from '../../components';
import {WebLayout} from '../../layouts';

const TemplateLibrary = ({t}) => (
  <WebLayout alwaysSticky={true}
    title="Mallikirjasto - Duuers"
    meta={[
      {name: 'description', content: 'Laadukas tarjoustyökalu - Duuers tarjoaa tarjoustyökaluohjelmiston pienille yrityksille. Nopea ja helppo käyttää. Rekisteröidy ilmaiseksi nyt!'},
      {name: 'keywords', content: ''},
    ]}
    >
    
<div class="top120 component-CreateProposalInfo">
	<div class="component-CreateProposalInfo__content">
		{/*<h2>Choose a template</h2>*/}
		<h2>{t("component.templatelibrary.title")}</h2>
		<div class="component-CreateProposalInfo__content--templates">
			<div class="component-CreateProposalInfo__content--thumbnail">
				<div>
				<a href="https://app.duuers.com/proposal/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InJlY2lwaWVudCJ9.eyJwcm9wb3NhbCI6IjYxMTY2MzNhY2RhZWJjMDAxY2YyMWQ2NSIsInByaW5jaXBhbCI6IjYxMTY2MzU0Y2RhZWJjMDAxY2YyMWQ2OCIsImlhdCI6MTYyODg1NzQ5NiwiYXVkIjoiaHR0cHM6Ly9hcHAuZHV1ZXJzLmNvbSIsImlzcyI6ImR1dWVycyIsInN1YiI6InJlY2lwaWVudCJ9.BrWbjIoKC7b9lmB7xzLNYV0ctCiy2q1LDncd8Hp2m1M" target="_blank" rel="noopener noreferrer">
				<img src="/images/templatethumbs/construction.png"/>
				</a>
				</div>
				
				<p class="name">Construction</p>
				
				<p class="date">09.04.2021 12:21</p>
			</div>
			
			<div class="component-CreateProposalInfo__content--thumbnail">
				<div>
					<a href="https://app.duuers.com/proposal/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InJlY2lwaWVudCJ9.eyJwcm9wb3NhbCI6IjYxMTY2NTU3Y2RhZWJjMDAxY2YyMWQ4OCIsInByaW5jaXBhbCI6IjYxMTY2NzI2Y2RhZWJjMDAxY2YyMWRiNyIsImlhdCI6MTYyODg1ODIxMSwiYXVkIjoiaHR0cHM6Ly9hcHAuZHV1ZXJzLmNvbSIsImlzcyI6ImR1dWVycyIsInN1YiI6InJlY2lwaWVudCJ9.GUQXtHfU-P7IsDEH3u39xjNRI-sQAECahUduQ9ON7O0" target="_blank" rel="noopener noreferrer">
					<img src="/images/templatethumbs/creative.png"/>
					</a>
				</div>
				
				<p class="name">Creative</p>
				
				<p class="date">12.08.2021 11:05</p>
			</div>
			
			<div class="component-CreateProposalInfo__content--thumbnail">
				<div>
					<a href="https://app.duuers.com/proposal/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InJlY2lwaWVudCJ9.eyJwcm9wb3NhbCI6IjYxMTM4NTdlY2RhZWJjMDAxY2YyMTZhNSIsInByaW5jaXBhbCI6IjYxMTM4NWE1Y2RhZWJjMDAxY2YyMTZhOCIsImlhdCI6MTYyODY2OTQ2MywiYXVkIjoiaHR0cHM6Ly9hcHAuZHV1ZXJzLmNvbSIsImlzcyI6ImR1dWVycyIsInN1YiI6InJlY2lwaWVudCJ9.2dutaZZiFTcdxrVpDDEg6_oOCwHeUu6nUXabw2ZiCIY" target="_blank" rel="noopener noreferrer">
					<img src="/images/templatethumbs/catering.png"/>
					</a>
				</div>
				
				<p class="name">Catering</p>
				
				<p class="date">09.06.2021 15:31</p>
			</div>
			
			<div class="component-CreateProposalInfo__content--thumbnail">
				<div>
					<a href="https://app.duuers.com/proposal/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InJlY2lwaWVudCJ9.eyJwcm9wb3NhbCI6IjYxMTY2MTNhY2RhZWJjMDAxY2YyMWQ1NSIsInByaW5jaXBhbCI6IjYxMTY2MWMyY2RhZWJjMDAxY2YyMWQ1OCIsImlhdCI6MTYyODg1Njk1MywiYXVkIjoiaHR0cHM6Ly9hcHAuZHV1ZXJzLmNvbSIsImlzcyI6ImR1dWVycyIsInN1YiI6InJlY2lwaWVudCJ9.vdBnYnlx48u9YxJCpMvlrtxKB6GhUeLKpc_Pu4-ROy0" target="_blank" rel="noopener noreferrer">
					<img src="/images/templatethumbs/clean.png"/>
					</a>
				</div>
				
				<p class="name">Cleaning</p>
				
				<p class="date">19.02.2021 14:30</p>
			</div>
			
		</div>
	</div>
</div>
    
    
    
    
    
    <section className="margin_auto max_width600 bg_white90 padding_top20 padding_bottom40 text-center">
    					<div className="font18 light dark_blue hyphens text top30">{t('pricelist.needhelp.text')}</div>
    					<div className="font18 light dark_blue hyphens text top30"><i className="fa fa-phone blue"></i>{t('component.featureTestimonial.choose.phone')}</div>
    					<div className="top20"><a className="blue" href={t('component.featureTestimonial.choose.linkkiurl')}>{t('component.featureTestimonial.choose.linkkiteksti')}</a></div>
		</section>	
    <SignupDivider/>
    <Footer/>
  </WebLayout>
)

export default withTranslation()(TemplateLibrary)
